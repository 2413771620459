import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileInput } from 'ngx-material-file-input';
import { File as ModelFile, File } from '../models/file';
import { baseUrl } from './api-config';
import { ResourcefulService } from './resourcefulService';


@Injectable({
    providedIn: 'root'
})
export class FileService extends ResourcefulService<ModelFile>{
    constructor(private httpClient: HttpClient) {
        super(httpClient, ModelFile);
    }

    open(file: ModelFile, desiresPdfVersion: boolean = false) {

        //get the requested file, add "?pdf=1" if user requests pdf version.
        return this.httpClient.get(`${baseUrl}/files/${file.id}${desiresPdfVersion ? '?pdf=1' : ''}`, {
            headers: {
                'responseType': 'blob',
                'Content-Type': 'application/json',
                'Accept': 'text/plain, */*',
                'Cache-Control': 'no-cache',
            },
            responseType: 'blob'
        });
    }


    convertToPdf(file: ModelFile): Promise<any> {
        return this.httpClient.get(`${baseUrl}/files/${file.id}/convertToPdf`).toPromise();
    }


    public create(data): Promise<any> {
        return this._httpClient.post(
            `${this.baseUrl}/${this.modelClass.apiUrl}`,
            this.toFormData(data)).toPromise();
    }


    /**
     * Funzione per l'upload multiplo di file
     * @deprecated invece che usare questa funziona "misteriosa", usa create singolo in un loop.
     * Così è più chiaro, e comunque non è compito del service gestire il fatto che vogliamo fare più upload
     */
    public createMultiple(data): Promise<any> {
        let fileInput = data.files;
        let files = fileInput.files;
        let promises = [];
        for (let file of files) {
            let model = { ...data } as any;
            model.file = file;
            this.count++;
            promises.push(this._httpClient.post(
                `${this.baseUrl}/${this.modelClass.apiUrl}`,
                this.toFormData(model),
            ).toPromise());

        }

        return Promise.all(promises);
    }

    public edit(model: File): Promise<any> {
        return this._httpClient.patch(
            `${this.baseUrl}/${this.modelClass.apiUrl}/${model.id}`,
            model,
        ).toPromise();
    }

    protected toFormData(data: { [key: string]: string | boolean }) {
        let formData = new FormData();
        for (let [key, val] of Object.entries(data)) {
            if (val !== undefined && val !== null) {
                if (key == 'file') {
                    let file = val as any;
                    formData.append(key, file, file.name);
                }
                else if (typeof val == 'string')
                    formData.append(key, val);
                else if (typeof val === 'boolean')
                    formData.append(key, val === true ? '1' : '0');
                else formData.append(key, JSON.stringify(val));
            }
        }
        return formData;
    }

    public uploadSignature(arg: { base64EncodedSignature: string, incarico_id: number }): Promise<File> {
        return this.httpClient.post<File>(`${baseUrl}/firme`, { firma: arg.base64EncodedSignature, incarico_id: arg.incarico_id }).toPromise();
    }

}




